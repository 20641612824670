.plantsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls Button {
  margin-right: 1em;
}
