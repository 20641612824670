.roundButton {
  border-radius: 50%;
  width: 2.8em;
  height: 2.8em;
}

.footer {
  justify-content: space-between;
  display: flex;
}

img {
  height: 2em;
}