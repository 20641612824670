.plant {
  margin: 0.25em;
  max-width: 47vw;
  width: 12em;
  cursor: grab;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.borrowedPlant {
  border-width: 4px;
  border-style: dashed;
}

.draggedPlant {
  opacity: 0.4;
}

.alarm {
  background-color: var(--alarm-bg-color);
  animation: alarm 0.8s ease-in-out infinite;
}

.customBadge > b {
  mix-blend-mode: difference;
  color:white;
}

.danger {
  background-color: var(--danger-bg-color);
}

.warning {
  background-color: var(--warning-bg-color);
}

.info {
  background-color: var(--info-bg-color);
}

.success {
  background-color: var(--success-bg-color);
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
}

.plant .controls {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  left: 70%;
  top: 27%;
}

.viewControls {
  display: flex;
  justify-content: space-between;
}

.viewControls:first-of-type {
  position: absolute;
  left: calc(100% - 6em);
}

.narrowCard {
  padding: 0.3em;
}

.plant:hover .controls {
  display: flex;
}

.label {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.header {
  margin-bottom: 0;
  margin-left: -0.3em;
}

.button {
  width: 100%;
}

.actions {
  display: grid;
  grid-template-columns: 0.9fr 1fr 0.9fr;
  justify-items: center;
  align-items: center;
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.8em;
  font-weight: bolder;
}

.imageGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBar {
  width: 80%;
  height: 1.2em;
  opacity: 0.5;
}

.progressGroup {
  width: 85%;
}

.progressGroup button {
  height: 1.1em;
  padding: 0;
  font-size: 0.8em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

@keyframes alarm {
  from {background-color: var(--alarm-bg-color);}
  to {background-color: var(--default-bg-color);}
}
