.image {
    height: 6em;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .autoInputWithColorPicker {
    display: grid;
    grid-template:
        "a c" auto
        "b b" auto / 1fr 0.1fr

  }

  .colorPickerContainer {
    grid-area: c;
    padding: .3rem .3rem;
  }

  .colorPickerInput {
    width: 2.5em;
  }

  .groupInput {
    width: auto!important;
  }

  .groupBadge {
    border-radius: 0.25rem 0 0 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
  }

  .groupBadge > b, .groupBadge > button {
    mix-blend-mode: difference;
    color:white;
  }