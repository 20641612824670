@import-normalize;

:root {
  --alarm-bg-color: #dc354560;
  --danger-bg-color: #fd7d141f;
  --warning-bg-color: #ffc10720;
  --info-bg-color: #0dcaf00A;
  --success-bg-color: #28a74505;
  --default-bg-color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


form .form-group:last-child {
  margin-bottom: 0;
}

main {
  text-align: center;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5.7em;
}

.navbar-collapse {
  flex-grow: unset;
}

i.icon {
  font-style: initial;
}

.icon-droplet:after {
  content: "\1f4a7"
}

.icon-settings:after {
  font-size: large;
  content: "⚙️";
}

.icon-save:after {
  content: "\1F4BE";
}

.icon-cross:after {
  content: "\2716";
}

.icon-location {
  font-size: 1.5em;
}

.icon-location:after {
  content: "\2316";
}

.icon-arrow-right:after {
  content: "\25B6"
}

.icon-arrow-left:after {
  content: "\25C0"
}

.icon-info:after {
  content: "\2139"
}

.alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
}

.card-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: .5rem;
}

.card-subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-link {
  text-decoration: none;
  padding-left: 1rem;
}

@media (prefers-color-scheme: dark) {
  html {
    filter: invert(1) hue-rotate(180deg);
  }

  html nav, .modal-header  {
    border-bottom: 1px solid gray;
  }

  html .card {
    border-color: gray;
  }

  html img{
    filter: invert(1) hue-rotate(180deg);
  }

  html {
    transition: color 300ms, background-color 300ms;
  }

  html .bg-info, .progress-bar, .card-body .btn-group .btn-primary, .text-muted, .btn.btn-success {
    color: rgb(50, 50, 50)!important;
  }

  main {
    background-color: #dae5da;
  }

  nav.bg-light {
    background-color: #cbdadc!important;
  }
}